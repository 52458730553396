/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Symbol';
  src: url('fonts/Symbol.ttf');
}

.font-symbol {
  font-family: 'Symbol', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Superclarendon-BlackItalic';
  src: url('fonts/Superclarendon-BlackItalic.ttf');
}

.font-superclarendonBlackItalic {
  font-family: 'Superclarendon-BlackItalic', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Helvetica-BoldOblique';
  src: url('fonts/Helvetica-BoldOblique.ttf');
}

.font-helveticaBoldOblique {
  font-family: 'Helvetica-BoldOblique', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Superclarendon-Regular';
  src: url('fonts/Superclarendon-Regular.ttf');
}

.font-superclarendonRegular {
  font-family: 'Superclarendon-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Silom';
  src: url('fonts/Silom.ttf');
}

.font-silom {
  font-family: 'Silom', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'SFUIText-Bold';
  src: url('fonts/BF396DF7D0C0-Bold.otf');
}

.font-SFUITextBold {
  font-family: 'SFUIText-Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'SourceSansPro-Semibold';
  src: url('fonts/SourceSansPro-Semibold.otf');
}

.font-sourceSansProSemibold {
  font-family: 'SourceSansPro-Semibold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('fonts/SourceSansPro-Regular.otf');
}

.font-sourceSansProRegular {
  font-family: 'SourceSansPro-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Futura-Bold';
  src: url('fonts/Futura-Bold.ttf');
}

.font-futuraBold {
  font-family: 'Futura-Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Roboto-Regular';
  src: url('fonts/Roboto-Regular.ttf');
}

.font-robotoRegular {
  font-family: 'Roboto-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Helvetica-Bold';
  src: url('fonts/Helvetica-Bold.ttf');
}

.font-helveticaBold {
  font-family: 'Helvetica-Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: '.AppleSystemUIFont';
  src: url('fonts/SFNS.ttf');
}

.font-AppleSystemUIFont {
  font-family: '.AppleSystemUIFont', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'DINAlternate-Bold';
  src: url('fonts/DIN Alternate Bold.ttf');
}

.font-DINAlternateBold {
  font-family: 'DINAlternate-Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #bee9ee;
}

.App .primaryBg {
  background-color: #bee9ee;
}

.App .highlightTextColor {
  color: #0093d5;
}

.App .highlightBg {
  background-color: #0093d5;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #bee9ee;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}
.mui-btn--accent {
    background-color: #0093d5;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #069fda;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    margin-bottom: 0;
}
.mui-textfield>input, .mui-textfield>textarea {
    font-size: inherit;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.0px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 15.0px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 20.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 20.0px;
}
.App .navBar {
  background-color: #bee9ee;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

.App {
  --tab-bar-height: 54px;
}
@media (display-mode: standalone) {
  .App {
    --tab-bar-height: 90px;
  }
}
.App .tabBar {
  height: var(--tab-bar-height);
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  z-index: 9999;
}
.App .tabBar > * {
  flex: 1 0 auto;
  text-align: center;
  cursor: pointer;
}
.App .tabBar div {
  box-sizing: border-box;
  font-size: 13px; /* default size for tab bar text */
  line-height: 15px;
}
@media (max-width: 767px) {
  .App .tabBar div {
    font-size: 11px; /* small screen size for tab bar text */
    line-height: 13px;
  }
}
.App .tabBar img {
  height: 24px;
  padding-top: 6px;
}
.App .tabBarMenu {
  overflow: hidden;
}
.App .tabBarPageContent {
  overflow: scroll;
  flex: 1;
}
.App .tabBarPageContent > .AppScreen {
  position: relative;
  overflow: scroll;
}
.App .tabBarPageContent > .AppScreen > .background {
  position: fixed;
  left: auto;
  top: auto;
  height: calc(100% - var(--tab-bar-height));
}
.App .tabBarPageContent > .AppScreen > .navBarContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.App .tabBarPageContent > .AppScreen > .navBarContainer > .navBar {
  position: fixed;
  left: auto;
  top: auto;
}
@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .EntDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .EntDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.EntDetailsScreen .elCategoriesTabs {
}

.EntDetailsScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .EntDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 150px;
    margin-right: 5px;
    width: calc(100.0% - 155px);
  }
  .EntDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 150px;
    margin-right: 5px;
    width: calc(100.0% - 155px);
  }
  .EntDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 149px;
    margin-right: 5px;
    width: calc(100.0% - 154px);
  }
  .EntDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 149px;
    margin-right: 5px;
    width: calc(100.0% - 154px);
  }
  .EntDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 457px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 457px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 454px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 454px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -55px;
    width: 130px;
    margin-left: 190px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -55px;
    width: 130px;
    margin-left: 190px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -54px;
    width: 130px;
    margin-left: 189px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -54px;
    width: 130px;
    margin-left: 189px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .EntDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -132px;
    width: 130px;
    margin-left: 190px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -132px;
    width: 130px;
    margin-left: 190px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -131px;
    width: 130px;
    margin-left: 189px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -131px;
    width: 130px;
    margin-left: 189px;
  }
  .EntDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elRules > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elRules > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elRules > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elRules > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: -286px;
    width: calc(100.0% - 214px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: 10px;
    width: calc(100.0% - 510px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .EntDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .EntDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: -321px;
    width: calc(100.0% - 179px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: 10px;
    width: calc(100.0% - 510px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .EntDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: 30px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: 20px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 320px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: 30px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: 30px;
  }
  .EntDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .EntDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.EntDetailsScreen .elFooter {
}

.EntDetailsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .EntDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .EntDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .EntDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.EntDetailsScreen .elHeaderComponent {
}

.EntDetailsScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* This component has a fixed-size layout */
  .EntItem {
    width: 100%; /* This component was designed using a width of 211px */
    height: 100%;
  }


  .EntItem > .background > .elClickableBg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 139px;
  }


  .EntItem > .layoutFlow > .elPicture {
    position: relative;
    margin-top: 10px;
    width: 70px;
    margin-left: 0px;
  }
  .EntItem > .layoutFlow > .elPicture > * { 
  width: 70px;
  }
  


  .EntItem > .layoutFlow > .elTitle {
    line-height: 22px;
    position: relative;
    margin-top: -70px;
    margin-left: calc(50.0% + -75px);
    margin-right: 5px;
    width: calc(50.0% - -70px);
  }
  .EntItem > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .EntItem > .layoutFlow > .elPictureGoogleBadge {
    position: relative;
    margin-top: 5px;
    width: 120px;
    margin-left: 80px;
  }
  .EntItem > .layoutFlow > .elPictureGoogleBadge > * { 
  width: 120px;
  }
  


  .EntItem > .layoutFlow > .elPictureAppleBadge {
    position: relative;
    margin-top: -44px;
    width: 100px;
    margin-left: 230px;
  }
  .EntItem > .layoutFlow > .elPictureAppleBadge > * { 
  width: 100px;
  }
  


  .EntItem > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 85px;
  }
  .EntItem > .layoutFlow > .elGoogleQRcode > * { 
  width: 100px;
  }
  


  .EntItem > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -102px;
    width: 100px;
    margin-left: 230px;
  }
  .EntItem > .layoutFlow > .elAppleQRcode > * { 
  width: 100px;
  }
  

.EntItem .elAppleHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .EntItem > .foreground > .elAppleHotspot {
    position: absolute;
    left: 250px;
    top: 39px;
    width: 98px;
    height: 36px;
  }

.EntItem .elGoogleHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .EntItem > .foreground > .elGoogleHotspot {
    position: absolute;
    left: 140px;
    top: 43px;
    width: 103px;
    height: 27px;
  }

/* This component has a fixed-size layout */
  .TopScorerComponent {
    min-height: 200px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .TopScorerComponent > .background > .containerMinHeight {
    min-height: 200px;
  }


  .TopScorerComponent > .layoutFlow > .elV5thGrade {
    line-height: 30px;
    position: relative;
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .TopScorerComponent > .layoutFlow > .elV5thGrade > * { 
    width: 100%;
  }
  


  .TopScorerComponent > .layoutFlow > .elCertificate1 {
    position: relative;
    margin-top: 0px;
    width: 160px;
    margin-left: 0px;
  }
  .TopScorerComponent > .layoutFlow > .elCertificate1 > * { 
  width: 160px;
  }
  


  .TopScorerComponent > .layoutFlow > .elCertificate2 {
    position: relative;
    margin-top: -110px;
    width: 160px;
    margin-left: 170px;
  }
  .TopScorerComponent > .layoutFlow > .elCertificate2 > * { 
  width: 160px;
  }
  


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MusicDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.MusicDetailsScreen .elCategoriesTabs {
}

.MusicDetailsScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 150px;
    margin-right: 5px;
    width: calc(100.0% - 155px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 150px;
    margin-right: 5px;
    width: calc(100.0% - 155px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 149px;
    margin-right: 5px;
    width: calc(100.0% - 154px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 149px;
    margin-right: 5px;
    width: calc(100.0% - 154px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 399px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 399px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 396px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 396px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -55px;
    width: 130px;
    margin-left: 190px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -55px;
    width: 130px;
    margin-left: 190px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -54px;
    width: 130px;
    margin-left: 189px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -54px;
    width: 130px;
    margin-left: 189px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MusicDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -132px;
    width: 130px;
    margin-left: 190px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -132px;
    width: 130px;
    margin-left: 190px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -131px;
    width: 130px;
    margin-left: 189px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -131px;
    width: 130px;
    margin-left: 189px;
  }
  .MusicDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRules > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRules > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRules > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRules > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: -286px;
    width: calc(100.0% - 214px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: 10px;
    width: calc(100.0% - 510px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MusicDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: -321px;
    width: calc(100.0% - 179px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: 10px;
    width: calc(100.0% - 510px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MusicDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: 30px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: 20px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 320px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: 30px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: 30px;
  }
  .MusicDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MusicDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.MusicDetailsScreen .elFooter {
}

.MusicDetailsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .MusicDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .MusicDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.MusicDetailsScreen .elHeaderComponent {
}

.MusicDetailsScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* This component has a fixed-size layout */
  .ImageTesting {
    width: 100%; /* This component was designed using a width of 350px */
    height: 100%;
  }


  .ImageTesting > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .ImageTesting > .layoutFlow > .elBanner982162_1920 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ImageTesting > .layoutFlow > .elBanner982162_1920 > * { 
    width: 100%;
  }
  


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .TopScorerScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .TopScorerScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .TopScorerScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.TopScorerScreen .elCategoriesTabs {
}

.TopScorerScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elTitle {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .TopScorerScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elTitle {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .TopScorerScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elTitle {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .TopScorerScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elTitle {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .TopScorerScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}

.TopScorerScreen > .layoutFlow > .elList5th > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 10px;
  grid-row-gap: 10px;  /* old name still required by some browsers */
  column-gap: 100px;
  grid-column-gap: 100px;  /* old name still required by some browsers */
}

.TopScorerScreen > .layoutFlow > .elList5th > div > * {
  display: inline-block;
  min-width: 320px;
  position: relative;
}

  @media (min-width: 1024px) {
    .TopScorerScreen > .layoutFlow > .elList5th > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 99px;
      grid-column-gap: 99px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .TopScorerScreen > .layoutFlow > .elList5th > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 99px;
      grid-column-gap: 99px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .TopScorerScreen > .layoutFlow > .elList5th > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 100px;
      grid-column-gap: 100px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .TopScorerScreen > .layoutFlow > .elList5th > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 100px;
      grid-column-gap: 100px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elList5th {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 10px;
  }
  .TopScorerScreen > .layoutFlow > .elList5th > * { 
  width: 250px;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elList5th {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 10px;
  }
  .TopScorerScreen > .layoutFlow > .elList5th > * { 
  width: 250px;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elList5th {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 10px;
  }
  .TopScorerScreen > .layoutFlow > .elList5th > * { 
  width: 248px;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elList5th {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 10px;
  }
  .TopScorerScreen > .layoutFlow > .elList5th > * { 
  width: 248px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elMoreText {
    line-height: 41px;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elMoreText {
    line-height: 41px;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elMoreText {
    line-height: 41px;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elMoreText {
    line-height: 41px;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elEmailLink {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .TopScorerScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elEmailLink {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .TopScorerScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elEmailLink {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .TopScorerScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elEmailLink {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .TopScorerScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elTextCopy2 {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elTextCopy2 {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elTextCopy2 {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elTextCopy2 {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}

.TopScorerScreen > .layoutFlow > .elMathList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.TopScorerScreen > .layoutFlow > .elMathList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .TopScorerScreen > .layoutFlow > .elMathList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .TopScorerScreen > .layoutFlow > .elMathList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .TopScorerScreen > .layoutFlow > .elMathList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .TopScorerScreen > .layoutFlow > .elMathList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elMathList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMathList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elMathList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMathList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elMathList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMathList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elMathList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMathList > * { 
    width: 100%;
  }
  
}

.TopScorerScreen > .layoutFlow > .elEntList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.TopScorerScreen > .layoutFlow > .elEntList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .TopScorerScreen > .layoutFlow > .elEntList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .TopScorerScreen > .layoutFlow > .elEntList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .TopScorerScreen > .layoutFlow > .elEntList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .TopScorerScreen > .layoutFlow > .elEntList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elEntList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elEntList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elEntList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elEntList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elEntList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elEntList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elEntList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elEntList > * { 
    width: 100%;
  }
  
}

.TopScorerScreen > .layoutFlow > .elMusicList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.TopScorerScreen > .layoutFlow > .elMusicList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .TopScorerScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .TopScorerScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .TopScorerScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .TopScorerScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TopScorerScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .TopScorerScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.TopScorerScreen .elFooter {
}

.TopScorerScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .TopScorerScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .TopScorerScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .TopScorerScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .TopScorerScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .TopScorerScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.TopScorerScreen .elHeaderComponent {
}

.TopScorerScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .TopScorerScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.PrivacyPolicyScreen .elCategoriesTabs {
}

.PrivacyPolicyScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elInformation {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elInformation > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elInformation {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elInformation > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elInformation {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elInformation > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elInformation {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elInformation > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGooglePlaySvcLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elAdMobLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elGoogleAnalyticsLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elCookies {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCookies > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elCookies {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCookies > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elCookies {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCookies > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elCookies {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elCookies > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy7 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elLinks {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elLinks > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elLinks {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elLinks > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elLinks {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elLinks > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elLinks {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elLinks > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy8 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChildrensPrivacy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy9 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elChanges {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChanges > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elChanges {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChanges > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elChanges {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChanges > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elChanges {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elChanges > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy10 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy11 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy12 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 252px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 251px;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.PrivacyPolicyScreen .elFooter {
}

.PrivacyPolicyScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .PrivacyPolicyScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.PrivacyPolicyScreen .elHeaderComponent {
}

.PrivacyPolicyScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* This component has a fixed-size layout */
  .ListItem {
    width: 100%; /* This component was designed using a width of 50px */
    height: 100%;
  }


  .ListItem > .background > .elClickableBg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 29px;
  }


  .ListItem > .layoutFlow > .elTitle {
    line-height: 24px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 5px;
    width: calc(100.0% - 15px);
  }
  .ListItem > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  

.ListItem .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ListItem > .foreground > .elHotspot {
    position: absolute;
    left: 15px;
    top: 0px;
    width: 28px;
    height: 29px;
  }

/* This component has a fixed-size layout */
  .ListEntItem {
    width: 100%; /* This component was designed using a width of 211px */
    height: 100%;
  }


  .ListEntItem > .background > .elClickableBg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 139px;
  }


  .ListEntItem > .layoutFlow > .elTitle {
    line-height: 24px;
    position: relative;
    margin-top: 7px;
    margin-left: 20px;
    margin-right: 5px;
    width: calc(100.0% - 25px);
  }
  .ListEntItem > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .ListEntItem > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 279px;
  }
  .ListEntItem > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

.ListEntItem .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ListEntItem > .foreground > .elHotspot {
    position: absolute;
    left: 17px;
    top: 0px;
    width: 160px;
    height: 34px;
  }

/* This component has a fixed-size layout */
  .ListMusicItem {
    width: 100%; /* This component was designed using a width of 211px */
    height: 100%;
  }


  .ListMusicItem > .background > .elClickableBg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 139px;
  }


  .ListMusicItem > .layoutFlow > .elTitle {
    line-height: 24px;
    position: relative;
    margin-top: 7px;
    margin-left: 20px;
    margin-right: 5px;
    width: calc(100.0% - 25px);
  }
  .ListMusicItem > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .ListMusicItem > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 279px;
  }
  .ListMusicItem > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

.ListMusicItem .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ListMusicItem > .foreground > .elHotspot {
    position: absolute;
    left: 17px;
    top: 0px;
    width: 160px;
    height: 34px;
  }


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .QAServicesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .QAServicesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .QAServicesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.QAServicesScreen .elCategoriesTabs {
}

.QAServicesScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elQAServices {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQAServices > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elQAServices {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQAServices > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elQAServices {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQAServices > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elQAServices {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQAServices > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elQaStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQaStatement > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elQaStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQaStatement > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elQaStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQaStatement > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elQaStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elQaStatement > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}

.QAServicesScreen .elImageTesting {
}

.QAServicesScreen .elImageTesting.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elImageTesting {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elImageTesting > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elImageTesting > .background > .containerMinHeight {
    min-height: 116px;
  }

@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elImageTesting {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elImageTesting > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elImageTesting > .background > .containerMinHeight {
    min-height: 116px;
  }

}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elImageTesting {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elImageTesting > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elImageTesting > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elImageTesting {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elImageTesting > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elImageTesting > .background > .containerMinHeight {
    min-height: 115px;
  }

}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elTeam {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeam > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elTeam {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeam > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elTeam {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeam > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elTeam {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeam > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elTeamText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeamText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elTeamText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeamText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elTeamText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeamText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elTeamText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elTeamText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elReporting {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReporting > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elReporting {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReporting > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elReporting {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReporting > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elReporting {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReporting > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elReportingText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReportingText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elReportingText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReportingText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elReportingText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReportingText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elReportingText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elReportingText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 198px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 198px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 197px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 197px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elJoinTeamText {
    line-height: 40px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elJoinTeamText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elJoinTeamText {
    line-height: 40px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elJoinTeamText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elJoinTeamText {
    line-height: 40px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elJoinTeamText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elJoinTeamText {
    line-height: 40px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elJoinTeamText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elSendResumeText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elSendResumeText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elSendResumeText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elSendResumeText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elSendResumeText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elSendResumeText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elSendResumeText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .QAServicesScreen > .layoutFlow > .elSendResumeText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .QAServicesScreen > .layoutFlow > .elCareersEmailLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.QAServicesScreen .elFooter {
}

.QAServicesScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QAServicesScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .QAServicesScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}


/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .QAServicesScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
}

.QAServicesScreen .elHeaderComponent {
}

.QAServicesScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .QAServicesScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* This component has a fixed-size layout */
  .Drawer3 {
    min-height: 5944px;  /* Minimum height to ensure layout flow is visible */
    width: 182px; /* This component has a fixed size */
  }
  .Drawer3 > .background > .containerMinHeight {
    min-height: 5944px;
  }


  .Drawer3 > .background > .state0_elBackground42840 {
    position: absolute;
    left: -187px;
    right: 0px;
    top: 0px;
    width: calc(100.0% - -187px - 0px);
    height: 100%;
  }


  .Drawer3 > .background > .state0_elBackgroundWhite612569 {
    position: absolute;
    left: -3px;
    top: 0px;
    width: 4px;
    height: 100%;
  }

.Drawer3 .state0_elHome615763 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state0_elHome615763 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state0_elHome615763 {
    line-height: 50px;
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state0_elHome615763 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state0_elMathApps button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state0_elMathApps button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state0_elMathApps {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state0_elMathApps > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state0_elEntApps button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state0_elEntApps button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state0_elEntApps {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state0_elEntApps > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state0_elMusicApps button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state0_elMusicApps button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state0_elMusicApps {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state0_elMusicApps > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state0_elTopScorers button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state0_elTopScorers button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state0_elTopScorers {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state0_elTopScorers > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state0_elPrivacyPolicy button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state0_elPrivacyPolicy button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state0_elPrivacyPolicy {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state0_elPrivacyPolicy > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state0_elQAServices button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Drawer3 .state0_elQAServices button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state0_elQAServices {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state0_elQAServices > * { 
    width: 100%;
  height: 51px;
  }
  

/* This component has a fixed-size layout */
  .Drawer3 {
    min-height: 5944px;  /* Minimum height to ensure layout flow is visible */
    width: 182px; /* This component has a fixed size */
  }
  .Drawer3 > .background > .containerMinHeight {
    min-height: 5944px;
  }


  .Drawer3 > .background > .state1_elBackground42840 {
    position: absolute;
    left: -187px;
    right: 0px;
    top: 0px;
    width: calc(100.0% - -187px - 0px);
    height: 100%;
  }


  .Drawer3 > .background > .state1_elBackgroundWhite612569 {
    position: absolute;
    left: -3px;
    top: 0px;
    width: 4px;
    height: 100%;
  }

.Drawer3 .state1_elHome615763 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state1_elHome615763 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state1_elHome615763 {
    line-height: 50px;
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state1_elHome615763 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state1_elMathAppsExp button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state1_elMathAppsExp button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state1_elMathAppsExp {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state1_elMathAppsExp > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 > .layoutFlow > .state1_elList > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2px;
  grid-row-gap: 2px;  /* old name still required by some browsers */
  column-gap: 1px;
  grid-column-gap: 1px;  /* old name still required by some browsers */
}

.Drawer3 > .layoutFlow > .state1_elList > div > * {
  display: inline-block;
  position: relative;
}

  @media (min-width: 1024px) {
    .Drawer3 > .layoutFlow > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2px;
      grid-row-gap: 2px;  /* old name still required by some browsers */
      column-gap: 1px;
      grid-column-gap: 1px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .Drawer3 > .layoutFlow > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2px;
      grid-row-gap: 2px;  /* old name still required by some browsers */
      column-gap: 1px;
      grid-column-gap: 1px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .Drawer3 > .layoutFlow > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2px;
      grid-row-gap: 2px;  /* old name still required by some browsers */
      column-gap: 1px;
      grid-column-gap: 1px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .Drawer3 > .layoutFlow > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2px;
      grid-row-gap: 2px;  /* old name still required by some browsers */
      column-gap: 1px;
      grid-column-gap: 1px;  /* old name still required by some browsers */
    }
  
  }
  


  .Drawer3 > .layoutFlow > .state1_elList {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 150px;
    width: calc(100.0% - 150px);
  }
  .Drawer3 > .layoutFlow > .state1_elList > * { 
    width: 100%;
  }
  

.Drawer3 .state1_elEntAppsState2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state1_elEntAppsState2 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state1_elEntAppsState2 {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state1_elEntAppsState2 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state1_elMusicAppsState2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state1_elMusicAppsState2 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state1_elMusicAppsState2 {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state1_elMusicAppsState2 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state1_elTopScorersState2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state1_elTopScorersState2 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state1_elTopScorersState2 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state1_elTopScorersState2 > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state1_elPrivacyPolicyState2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state1_elPrivacyPolicyState2 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state1_elPrivacyPolicyState2 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state1_elPrivacyPolicyState2 > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state1_elQAServicesState2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Drawer3 .state1_elQAServicesState2 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state1_elQAServicesState2 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state1_elQAServicesState2 > * { 
    width: 100%;
  height: 51px;
  }
  

/* This component has a fixed-size layout */
  .Drawer3 {
    min-height: 5944px;  /* Minimum height to ensure layout flow is visible */
    width: 182px; /* This component has a fixed size */
  }
  .Drawer3 > .background > .containerMinHeight {
    min-height: 5944px;
  }


  .Drawer3 > .background > .state2_elBackground42840 {
    position: absolute;
    left: -187px;
    right: 0px;
    top: 0px;
    width: calc(100.0% - -187px - 0px);
    height: 100%;
  }


  .Drawer3 > .background > .state2_elBackgroundWhite612569 {
    position: absolute;
    left: -3px;
    top: 0px;
    width: 4px;
    height: 100%;
  }

.Drawer3 .state2_elHome615763 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state2_elHome615763 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state2_elHome615763 {
    line-height: 50px;
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elHome615763 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state2_elMathAppsState3 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state2_elMathAppsState3 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state2_elMathAppsState3 {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elMathAppsState3 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state2_elEntAppsExp button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state2_elEntAppsExp button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state2_elEntAppsExp {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elEntAppsExp > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state2_elEntList li {
  margin-bottom: 5px;
}



  .Drawer3 > .layoutFlow > .state2_elEntList {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elEntList > * { 
    width: 100%;
  }
  

.Drawer3 .state2_elMusicAppsState3 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state2_elMusicAppsState3 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state2_elMusicAppsState3 {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elMusicAppsState3 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state2_elTopScorersState3 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state2_elTopScorersState3 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state2_elTopScorersState3 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elTopScorersState3 > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state2_elPrivacyPolicyState3 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state2_elPrivacyPolicyState3 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state2_elPrivacyPolicyState3 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elPrivacyPolicyState3 > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state2_elQAServicesState3 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Drawer3 .state2_elQAServicesState3 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state2_elQAServicesState3 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state2_elQAServicesState3 > * { 
    width: 100%;
  height: 51px;
  }
  

/* This component has a fixed-size layout */
  .Drawer3 {
    min-height: 5944px;  /* Minimum height to ensure layout flow is visible */
    width: 182px; /* This component has a fixed size */
  }
  .Drawer3 > .background > .containerMinHeight {
    min-height: 5944px;
  }


  .Drawer3 > .background > .state3_elBackground42840 {
    position: absolute;
    left: -187px;
    right: 0px;
    top: 0px;
    width: calc(100.0% - -187px - 0px);
    height: 100%;
  }


  .Drawer3 > .background > .state3_elBackgroundWhite612569 {
    position: absolute;
    left: -3px;
    top: 0px;
    width: 4px;
    height: 100%;
  }

.Drawer3 .state3_elHome615763 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state3_elHome615763 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state3_elHome615763 {
    line-height: 50px;
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elHome615763 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state3_elMathAppsState4 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state3_elMathAppsState4 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state3_elMathAppsState4 {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elMathAppsState4 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state3_elEntAppsState4 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state3_elEntAppsState4 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state3_elEntAppsState4 {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elEntAppsState4 > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state3_elMusicAppsExp button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state3_elMusicAppsExp button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state3_elMusicAppsExp {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elMusicAppsExp > * { 
    width: 100%;
  height: 50px;
  }
  

.Drawer3 .state3_elMusicList li {
  margin-bottom: 5px;
}



  .Drawer3 > .layoutFlow > .state3_elMusicList {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elMusicList > * { 
    width: 100%;
  }
  

.Drawer3 .state3_elTopScorersState4 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state3_elTopScorersState4 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state3_elTopScorersState4 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elTopScorersState4 > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state3_elPrivacyPolicyState4 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer3 .state3_elPrivacyPolicyState4 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state3_elPrivacyPolicyState4 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elPrivacyPolicyState4 > * { 
    width: 100%;
  height: 51px;
  }
  

.Drawer3 .state3_elQAServicesState4 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Drawer3 .state3_elQAServicesState4 button:focus {
  outline: none;
}



  .Drawer3 > .layoutFlow > .state3_elQAServicesState4 {
    line-height: 51px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Drawer3 > .layoutFlow > .state3_elQAServicesState4 > * { 
    width: 100%;
  height: 51px;
  }
  

/* This component has a fixed-size layout */
  .ImageEntertainment {
    width: 100%; /* This component was designed using a width of 350px */
    height: 100%;
  }


  .ImageEntertainment > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .ImageEntertainment > .layoutFlow > .elBanner982162_1920 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ImageEntertainment > .layoutFlow > .elBanner982162_1920 > * { 
    width: 100%;
  }
  

/* Narrowest size for component's responsive layout */
  .HeaderComponent {
    min-height: 70px;  /* This component has a fixed size */
    overflow: hidden;
  }
  .HeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }
@media (min-width: 568px) {
  .HeaderComponent {
    min-height: 70px;  /* This component has a fixed size */
    overflow: hidden;
  }
  .HeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }
}
@media (min-width: 768px) {
  .HeaderComponent {
    min-height: 70px;  /* This component has a fixed size */
    overflow: hidden;
  }
  .HeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }
}
@media (min-width: 1024px) {
  .HeaderComponent {
    min-height: 70px;  /* This component has a fixed size */
    overflow: hidden;
  }
  .HeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HeaderComponent > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .HeaderComponent > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .HeaderComponent > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .HeaderComponent > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.HeaderComponent .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.HeaderComponent .elIconButton:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HeaderComponent > .foreground > .elIconButton {
    position: absolute;
    right: 5px;
    top: 20px;
    width: 41px;
    height: 33px;
  }
@media (min-width: 568px) {
  .HeaderComponent > .foreground > .elIconButton {
    right: 5px;
    top: 20px;
    width: 41px;
    height: 33px;
  }
}
@media (min-width: 768px) {
  .HeaderComponent > .foreground > .elIconButton {
    right: 5px;
    top: 20px;
    width: 41px;
    height: 33px;
  }
}
@media (min-width: 1024px) {
  .HeaderComponent > .foreground > .elIconButton {
    right: 5px;
    top: 20px;
    width: 41px;
    height: 33px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HeaderComponent > .foreground > .elText {
    line-height: 31px;
    position: absolute;
    left: 80px;
    right: 40px;
    top: 5px;
    width: calc(100.0% - 80px - 40px);
    height: 98px;
  }
@media (min-width: 568px) {
  .HeaderComponent > .foreground > .elText {
    line-height: 31px;
    left: 120px;
    right: 50px;
    top: 20px;
    width: calc(100.0% - 120px - 50px);
    height: 98px;
  }
}
@media (min-width: 768px) {
  .HeaderComponent > .foreground > .elText {
    line-height: 31px;
    left: 80px;
    right: 40px;
    top: 25px;
    width: calc(100.0% - 80px - 40px);
    height: 98px;
  }
}
@media (min-width: 1024px) {
  .HeaderComponent > .foreground > .elText {
    line-height: 31px;
    left: 0px;
    right: 40px;
    top: 25px;
    width: calc(100.0% - 0px - 40px);
    height: 66px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HeaderComponent > .foreground > .elN2009Copy {
    position: absolute;
    left: 10px;
    top: 0px;
    width: 70px;
    height: 68px;
  }
@media (min-width: 568px) {
  .HeaderComponent > .foreground > .elN2009Copy {
    left: 10px;
    top: 0px;
    width: 70px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .HeaderComponent > .foreground > .elN2009Copy {
    left: 10px;
    top: 0px;
    width: 70px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .HeaderComponent > .foreground > .elN2009Copy {
    left: 10px;
    top: 0px;
    width: 70px;
    height: 68px;
  }
}

.HeaderComponent > .drawer3 > .drawerContent {
  right: 0px;
}


/* This component has a fixed-size layout */
  .ImageMusic {
    width: 100%; /* This component was designed using a width of 350px */
    height: 100%;
  }


  .ImageMusic > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .ImageMusic > .layoutFlow > .elBanner982162_1920 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ImageMusic > .layoutFlow > .elBanner982162_1920 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Social {
    min-height: 130px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Social > .background > .containerMinHeight {
    min-height: 130px;
  }


  .Social > .foreground > .elYoutubecircle {
    position: absolute;
    left: calc(80.0% + 0px - 30px);
    top: 30px;
    width: 60px;
    height: 60px;
  }


  .Social > .foreground > .elTwitter_circle {
    position: absolute;
    left: calc(60.0% + 0px - 30px);
    top: 30px;
    width: 60px;
    height: 60px;
  }


  .Social > .foreground > .elIg_circle {
    position: absolute;
    left: calc(40.0% + 0px - 30px);
    top: 30px;
    width: 60px;
    height: 60px;
  }


  .Social > .foreground > .elFb_circle {
    position: absolute;
    left: calc(20.0% + 0px - 30px);
    top: 30px;
    width: 60px;
    height: 60px;
  }


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.EntertainmentAppsScreen .elCategoriesTabs {
}

.EntertainmentAppsScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elText {
    line-height: 53px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elText {
    line-height: 53px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elText {
    line-height: 53px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elText {
    line-height: 53px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}

.EntertainmentAppsScreen .elImageComponent {
}

.EntertainmentAppsScreen .elImageComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 116px;
  }

@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 116px;
  }

}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 115px;
  }

}


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}

.EntertainmentAppsScreen > .layoutFlow > .elList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 50px;
  grid-row-gap: 50px;  /* old name still required by some browsers */
  column-gap: 25px;
  grid-column-gap: 25px;  /* old name still required by some browsers */
}

.EntertainmentAppsScreen > .layoutFlow > .elList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .EntertainmentAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .EntertainmentAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .EntertainmentAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .EntertainmentAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 300px;
    margin-left: 10px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 300px;
    margin-left: 70px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 298px;
    margin-left: 70px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 298px;
    margin-left: 70px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 50px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 100px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .EntertainmentAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.EntertainmentAppsScreen .elFooter {
}

.EntertainmentAppsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .EntertainmentAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .EntertainmentAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.EntertainmentAppsScreen .elHeaderComponent {
}

.EntertainmentAppsScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .EntertainmentAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MusicAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MusicAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.MusicAppsScreen .elCategoriesTabs {
}

.MusicAppsScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elText {
    line-height: 48px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MusicAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elText {
    line-height: 48px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MusicAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elText {
    line-height: 48px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MusicAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elText {
    line-height: 48px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MusicAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}

.MusicAppsScreen .elImageComponent {
}

.MusicAppsScreen .elImageComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 116px;
  }

@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 116px;
  }

}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 115px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MusicAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MusicAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MusicAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MusicAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}

.MusicAppsScreen > .layoutFlow > .elList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 50px;
  grid-row-gap: 50px;  /* old name still required by some browsers */
  column-gap: 25px;
  grid-column-gap: 25px;  /* old name still required by some browsers */
}

.MusicAppsScreen > .layoutFlow > .elList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .MusicAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .MusicAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .MusicAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .MusicAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .MusicAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .MusicAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .MusicAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .MusicAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 300px;
    margin-left: 10px;
  }
  .MusicAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 300px;
    margin-left: 70px;
  }
  .MusicAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 298px;
    margin-left: 70px;
  }
  .MusicAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 298px;
    margin-left: 70px;
  }
  .MusicAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 50px;
  }
  .MusicAppsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 100px;
  }
  .MusicAppsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .MusicAppsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .MusicAppsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MusicAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MusicAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MusicAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MusicAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MusicAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.MusicAppsScreen .elFooter {
}

.MusicAppsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .MusicAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .MusicAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .MusicAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MusicAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MusicAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.MusicAppsScreen .elHeaderComponent {
}

.MusicAppsScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MusicAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* Narrowest size for component's responsive layout */
  .Footer {
  }
@media (min-width: 568px) {
  .Footer {
  }
}
@media (min-width: 768px) {
  .Footer {
  }
}
@media (min-width: 1024px) {
  .Footer {
  }
}


/* Narrowest size for this element's responsive layout */
  .Footer > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Footer > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Footer > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Footer > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.Footer .elSocial {
}

.Footer .elSocial.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elSocial {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Footer > .layoutFlow > .elSocial > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elSocial {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Footer > .layoutFlow > .elSocial > * { 
  width: 320px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elSocial {
    position: relative;
    margin-top: 0px;
    width: 318px;
    margin-left: calc(50.0% + -159px);
  }
  .Footer > .layoutFlow > .elSocial > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elSocial {
    position: relative;
    margin-top: 0px;
    width: 318px;
    margin-left: calc(50.0% + -159px);
  }
  .Footer > .layoutFlow > .elSocial > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elAboutUs {
    line-height: 25px;
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Footer > .layoutFlow > .elAboutUs > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elAboutUs {
    line-height: 25px;
    position: relative;
    margin-top: 0px;
    width: 319px;
    margin-left: calc(50.0% + -330px);
  }
  .Footer > .layoutFlow > .elAboutUs > * { 
  width: 319px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elAboutUs {
    line-height: 25px;
    position: relative;
    margin-top: 0px;
    width: 318px;
    margin-left: calc(50.0% + -330px);
  }
  .Footer > .layoutFlow > .elAboutUs > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elAboutUs {
    line-height: 25px;
    position: relative;
    margin-top: 0px;
    width: 318px;
    margin-left: calc(50.0% + -330px);
  }
  .Footer > .layoutFlow > .elAboutUs > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elMathApps {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -162px);
  }
  .Footer > .layoutFlow > .elMathApps > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elMathApps {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 319px;
    margin-left: calc(50.0% + -330px);
  }
  .Footer > .layoutFlow > .elMathApps > * { 
  width: 319px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elMathApps {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: calc(50.0% + -330px);
  }
  .Footer > .layoutFlow > .elMathApps > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elMathApps {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: calc(50.0% + -330px);
  }
  .Footer > .layoutFlow > .elMathApps > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elEntertainment {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -158px);
  }
  .Footer > .layoutFlow > .elEntertainment > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elEntertainment {
    line-height: 25px;
    position: relative;
    margin-top: -61px;
    width: 319px;
    margin-left: calc(50.0% + -150px);
  }
  .Footer > .layoutFlow > .elEntertainment > * { 
  width: 319px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elEntertainment {
    line-height: 25px;
    position: relative;
    margin-top: -61px;
    width: 318px;
    margin-left: calc(50.0% + -150px);
  }
  .Footer > .layoutFlow > .elEntertainment > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elEntertainment {
    line-height: 25px;
    position: relative;
    margin-top: -61px;
    width: 318px;
    margin-left: calc(50.0% + -150px);
  }
  .Footer > .layoutFlow > .elEntertainment > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elMusic {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -163px);
  }
  .Footer > .layoutFlow > .elMusic > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elMusic {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 319px;
    margin-left: calc(50.0% + -163px);
  }
  .Footer > .layoutFlow > .elMusic > * { 
  width: 319px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elMusic {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: calc(50.0% + -162px);
  }
  .Footer > .layoutFlow > .elMusic > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elMusic {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: calc(50.0% + -162px);
  }
  .Footer > .layoutFlow > .elMusic > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elQAServices {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -163px);
  }
  .Footer > .layoutFlow > .elQAServices > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elQAServices {
    line-height: 25px;
    position: relative;
    margin-top: -61px;
    width: 319px;
    margin-left: calc(50.0% + 50px);
  }
  .Footer > .layoutFlow > .elQAServices > * { 
  width: 319px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elQAServices {
    line-height: 25px;
    position: relative;
    margin-top: -61px;
    width: 318px;
    margin-left: calc(50.0% + 50px);
  }
  .Footer > .layoutFlow > .elQAServices > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elQAServices {
    line-height: 25px;
    position: relative;
    margin-top: -61px;
    width: 318px;
    margin-left: calc(50.0% + 50px);
  }
  .Footer > .layoutFlow > .elQAServices > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elPrivacyPolicy {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -163px);
  }
  .Footer > .layoutFlow > .elPrivacyPolicy > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elPrivacyPolicy {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 319px;
    margin-left: calc(50.0% + 57px);
  }
  .Footer > .layoutFlow > .elPrivacyPolicy > * { 
  width: 319px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elPrivacyPolicy {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: calc(50.0% + 57px);
  }
  .Footer > .layoutFlow > .elPrivacyPolicy > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elPrivacyPolicy {
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: calc(50.0% + 57px);
  }
  .Footer > .layoutFlow > .elPrivacyPolicy > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 117px;
  }
  .Footer > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 117px;
  }
  .Footer > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 116px;
  }
  .Footer > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 116px;
  }
  .Footer > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Footer > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 110px;
  }
  .Footer > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .Footer > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 110px;
  }
  .Footer > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .Footer > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 110px;
  }
  .Footer > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .Footer > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 110px;
  }
  .Footer > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MathDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MathDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.MathDetailsScreen .elCategoriesTabs {
}

.MathDetailsScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 36px;
  }
  .MathDetailsScreen > .layoutFlow > .elGrade5512_play_icon > * { 
  width: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 150px;
    margin-right: 5px;
    width: calc(100.0% - 155px);
  }
  .MathDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 150px;
    margin-right: 5px;
    width: calc(100.0% - 155px);
  }
  .MathDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 149px;
    margin-right: 5px;
    width: calc(100.0% - 154px);
  }
  .MathDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elText {
    line-height: 36px;
    position: relative;
    margin-top: -90px;
    margin-left: 149px;
    margin-right: 5px;
    width: calc(100.0% - 154px);
  }
  .MathDetailsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 347px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 347px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 345px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 345px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore {
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGooglePlayStore > * { 
  width: 150px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -55px;
    width: 130px;
    margin-left: 190px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -55px;
    width: 130px;
    margin-left: 190px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -54px;
    width: 130px;
    margin-left: 189px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore {
    position: relative;
    margin-top: -54px;
    width: 130px;
    margin-left: 189px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleAppStore > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 130px;
    margin-left: 5px;
  }
  .MathDetailsScreen > .layoutFlow > .elGoogleQRcode > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -132px;
    width: 130px;
    margin-left: 190px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -132px;
    width: 130px;
    margin-left: 190px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -131px;
    width: 130px;
    margin-left: 189px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -131px;
    width: 130px;
    margin-left: 189px;
  }
  .MathDetailsScreen > .layoutFlow > .elAppleQRcode > * { 
  width: 130px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elRules > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elRules > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elRules > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elRules {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elRules > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: -286px;
    width: calc(100.0% - 214px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: 10px;
    width: calc(100.0% - 510px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MathDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elRulesCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MathDetailsScreen > .layoutFlow > .elRulesCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elStartplaying {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplaying > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: -321px;
    width: calc(100.0% - 179px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 {
    line-height: 35px;
    position: relative;
    margin-top: -445px;
    margin-left: 500px;
    margin-right: 10px;
    width: calc(100.0% - 510px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 250px;
  height: 400px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 250px;
  height: 400px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy {
    position: relative;
    margin-top: 10px;
    width: 248px;
    margin-left: 500px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy > * { 
  width: 248px;
  height: 398px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .MathDetailsScreen > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: 30px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: 20px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 320px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: 30px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 {
    position: relative;
    margin-top: 10px;
    width: 318px;
    margin-left: 30px;
  }
  .MathDetailsScreen > .layoutFlow > .elStartplayingCopy2 > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 272px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 270px;
  }
  .MathDetailsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.MathDetailsScreen .elFooter {
}

.MathDetailsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .MathDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .MathDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .MathDetailsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathDetailsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathDetailsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.MathDetailsScreen .elHeaderComponent {
}

.MathDetailsScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathDetailsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* This component has a fixed-size layout */
  .ImageComponent {
    width: 100%; /* This component was designed using a width of 350px */
    height: 100%;
  }


  .ImageComponent > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .ImageComponent > .layoutFlow > .elBanner982162_1920 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ImageComponent > .layoutFlow > .elBanner982162_1920 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .AdvItem {
    width: 100%; /* This component was designed using a width of 211px */
    height: 100%;
  }


  .AdvItem > .background > .elClickableBg {
    position: absolute;
    left: 6px;
    right: 238px;
    top: 5px;
    width: calc(100.0% - 6px - 238px);
    height: 74px;
  }


  .AdvItem > .layoutFlow > .elPicture {
    position: relative;
    margin-top: 10px;
    width: 70px;
    margin-left: 0px;
  }
  .AdvItem > .layoutFlow > .elPicture > * { 
  width: 70px;
  }
  


  .AdvItem > .layoutFlow > .elTitle {
    line-height: 22px;
    position: relative;
    margin-top: -70px;
    margin-left: calc(43.0% + -50px);
    margin-right: 5px;
    width: calc(57.0% - -45px);
  }
  .AdvItem > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .AdvItem > .layoutFlow > .elPictureGoogleBadge {
    position: relative;
    margin-top: 5px;
    width: 120px;
    margin-left: 80px;
  }
  .AdvItem > .layoutFlow > .elPictureGoogleBadge > * { 
  width: 120px;
  }
  


  .AdvItem > .layoutFlow > .elPictureAppleBadge {
    position: relative;
    margin-top: -44px;
    width: 100px;
    margin-left: 230px;
  }
  .AdvItem > .layoutFlow > .elPictureAppleBadge > * { 
  width: 100px;
  }
  


  .AdvItem > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 85px;
  }
  .AdvItem > .layoutFlow > .elGoogleQRcode > * { 
  width: 100px;
  }
  


  .AdvItem > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -102px;
    width: 100px;
    margin-left: 230px;
  }
  .AdvItem > .layoutFlow > .elAppleQRcode > * { 
  width: 100px;
  }
  

.AdvItem .elAppleHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .AdvItem > .foreground > .elAppleHotspot {
    position: absolute;
    left: 248px;
    top: 55px;
    width: 80px;
    height: 27px;
  }

.AdvItem .elGoogleHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .AdvItem > .foreground > .elGoogleHotspot {
    position: absolute;
    left: 136px;
    top: 60px;
    width: 89px;
    height: 27px;
  }

/* This component has a fixed-size layout */
  .Video {
    min-height: 320px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Video > .background > .containerMinHeight {
    min-height: 320px;
  }


  .Video > .foreground > .elEmbed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 320px;
  }


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MathAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MathAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MathAppsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.MathAppsScreen .elCategoriesTabs {
}

.MathAppsScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 37px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elText {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MathAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elText {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MathAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elText {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MathAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elText {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MathAppsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}

.MathAppsScreen .elImageComponent {
}

.MathAppsScreen .elImageComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 116px;
  }

@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 116px;
  }

}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elImageComponent {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elImageComponent > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elImageComponent > .background > .containerMinHeight {
    min-height: 115px;
  }

}

.MathAppsScreen .elVideo {
}

.MathAppsScreen .elVideo.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elVideo {
    position: relative;
    margin-top: 10px;
    width: 560px;
    margin-left: calc(50.0% - 280px);
  }
  .MathAppsScreen > .layoutFlow > .elVideo > * { 
  width: 560px;
  }
  
  .MathAppsScreen > .layoutFlow > .elVideo > .background > .containerMinHeight {
    min-height: 320px;
  }

@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elVideo {
    position: relative;
    margin-top: 10px;
    width: 560px;
    margin-left: calc(50.0% - 280px);
  }
  .MathAppsScreen > .layoutFlow > .elVideo > * { 
  width: 560px;
  }
  
  .MathAppsScreen > .layoutFlow > .elVideo > .background > .containerMinHeight {
    min-height: 320px;
  }

}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elVideo {
    position: relative;
    margin-top: 10px;
    width: 557px;
    margin-left: calc(50.0% - 278px);
  }
  .MathAppsScreen > .layoutFlow > .elVideo > * { 
  width: 557px;
  }
  
  .MathAppsScreen > .layoutFlow > .elVideo > .background > .containerMinHeight {
    min-height: 319px;
  }

}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elVideo {
    position: relative;
    margin-top: 10px;
    width: 557px;
    margin-left: calc(50.0% - 278px);
  }
  .MathAppsScreen > .layoutFlow > .elVideo > * { 
  width: 557px;
  }
  
  .MathAppsScreen > .layoutFlow > .elVideo > .background > .containerMinHeight {
    min-height: 319px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MathAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MathAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MathAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elTextCopy {
    line-height: 41px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MathAppsScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}

.MathAppsScreen > .layoutFlow > .elList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 50px;
  grid-row-gap: 50px;  /* old name still required by some browsers */
  column-gap: 25px;
  grid-column-gap: 25px;  /* old name still required by some browsers */
}

.MathAppsScreen > .layoutFlow > .elList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .MathAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .MathAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .MathAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .MathAppsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .MathAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .MathAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .MathAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .MathAppsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 300px;
    margin-left: 10px;
  }
  .MathAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 300px;
    margin-left: 70px;
  }
  .MathAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 298px;
    margin-left: 70px;
  }
  .MathAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 50px;
    width: 298px;
    margin-left: 70px;
  }
  .MathAppsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 50px;
  }
  .MathAppsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 100px;
  }
  .MathAppsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .MathAppsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .MathAppsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MathAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 101px);
  }
  .MathAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MathAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elMoreText {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .MathAppsScreen > .layoutFlow > .elMoreText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 257px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 256px;
  }
  .MathAppsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.MathAppsScreen .elFooter {
}

.MathAppsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

@media (min-width: 568px) {
  .MathAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 768px) {
  .MathAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}
@media (min-width: 1024px) {
  .MathAppsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MathAppsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .MathAppsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 127px;
  }

}

.MathAppsScreen .elHeaderComponent {
}

.MathAppsScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .MathAppsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* Narrowest size for component's responsive layout */
  .CategoriesTabs {
  }
@media (min-width: 568px) {
  .CategoriesTabs {
  }
}
@media (min-width: 768px) {
  .CategoriesTabs {
  }
}
@media (min-width: 1024px) {
  .CategoriesTabs {
  }
}


/* Narrowest size for this element's responsive layout */
  .CategoriesTabs > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .CategoriesTabs > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .CategoriesTabs > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .CategoriesTabs > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.CategoriesTabs .elMathApps button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CategoriesTabs .elMathApps button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CategoriesTabs > .layoutFlow > .elMathApps {
    line-height: 40px;
    position: relative;
    margin-top: 0px;
    width: 145px;
    margin-left: calc(50.0% + -175px);
  }
  .CategoriesTabs > .layoutFlow > .elMathApps > * { 
  width: 145px;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .CategoriesTabs > .layoutFlow > .elMathApps {
    line-height: 40px;
    position: relative;
    margin-top: 0px;
    width: 145px;
    margin-left: calc(50.0% + -320px);
  }
  .CategoriesTabs > .layoutFlow > .elMathApps > * { 
  width: 145px;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .CategoriesTabs > .layoutFlow > .elMathApps {
    line-height: 40px;
    position: relative;
    margin-top: 0px;
    width: 144px;
    margin-left: calc(50.0% + -320px);
  }
  .CategoriesTabs > .layoutFlow > .elMathApps > * { 
  width: 144px;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .CategoriesTabs > .layoutFlow > .elMathApps {
    line-height: 40px;
    position: relative;
    margin-top: 0px;
    width: 144px;
    margin-left: calc(50.0% + -320px);
  }
  .CategoriesTabs > .layoutFlow > .elMathApps > * { 
  width: 144px;
  height: 40px;
  }
  
}

.CategoriesTabs .elEntertainment button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CategoriesTabs .elEntertainment button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CategoriesTabs > .layoutFlow > .elEntertainment {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 200px;
    margin-left: calc(50.0% + -25px);
  }
  .CategoriesTabs > .layoutFlow > .elEntertainment > * { 
  width: 200px;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .CategoriesTabs > .layoutFlow > .elEntertainment {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 200px;
    margin-left: calc(50.0% + -166px);
  }
  .CategoriesTabs > .layoutFlow > .elEntertainment > * { 
  width: 200px;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .CategoriesTabs > .layoutFlow > .elEntertainment {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 199px;
    margin-left: calc(50.0% + -166px);
  }
  .CategoriesTabs > .layoutFlow > .elEntertainment > * { 
  width: 199px;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .CategoriesTabs > .layoutFlow > .elEntertainment {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 199px;
    margin-left: calc(50.0% + -166px);
  }
  .CategoriesTabs > .layoutFlow > .elEntertainment > * { 
  width: 199px;
  height: 40px;
  }
  
}

.CategoriesTabs .elMusic button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CategoriesTabs .elMusic button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CategoriesTabs > .layoutFlow > .elMusic {
    line-height: 40px;
    position: relative;
    margin-top: 1px;
    width: 100px;
    margin-left: calc(50.0% + -130px);
  }
  .CategoriesTabs > .layoutFlow > .elMusic > * { 
  width: 100px;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .CategoriesTabs > .layoutFlow > .elMusic {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 100px;
    margin-left: calc(50.0% + 42px);
  }
  .CategoriesTabs > .layoutFlow > .elMusic > * { 
  width: 100px;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .CategoriesTabs > .layoutFlow > .elMusic {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 99px;
    margin-left: calc(50.0% + 42px);
  }
  .CategoriesTabs > .layoutFlow > .elMusic > * { 
  width: 99px;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .CategoriesTabs > .layoutFlow > .elMusic {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 99px;
    margin-left: calc(50.0% + 42px);
  }
  .CategoriesTabs > .layoutFlow > .elMusic > * { 
  width: 99px;
  height: 40px;
  }
  
}

.CategoriesTabs .elQAServices button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CategoriesTabs .elQAServices button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CategoriesTabs > .layoutFlow > .elQAServices {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 170px;
    margin-left: calc(50.0% + -24px);
  }
  .CategoriesTabs > .layoutFlow > .elQAServices > * { 
  width: 170px;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .CategoriesTabs > .layoutFlow > .elQAServices {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 170px;
    margin-left: calc(50.0% + 150px);
  }
  .CategoriesTabs > .layoutFlow > .elQAServices > * { 
  width: 170px;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .CategoriesTabs > .layoutFlow > .elQAServices {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 169px;
    margin-left: calc(50.0% + 150px);
  }
  .CategoriesTabs > .layoutFlow > .elQAServices > * { 
  width: 169px;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .CategoriesTabs > .layoutFlow > .elQAServices {
    line-height: 40px;
    position: relative;
    margin-top: -40px;
    width: 169px;
    margin-left: calc(50.0% + 150px);
  }
  .CategoriesTabs > .layoutFlow > .elQAServices > * { 
  width: 169px;
  height: 40px;
  }
  
}

/* This component has a fixed-size layout */
  .ImageHome {
    width: 100%; /* This component was designed using a width of 350px */
    height: 100%;
  }


  .ImageHome > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .ImageHome > .layoutFlow > .elBanner982162_1920 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ImageHome > .layoutFlow > .elBanner982162_1920 > * { 
    width: 100%;
  }
  


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.AboutUsScreen .elCategoriesTabs {
}

.AboutUsScreen .elCategoriesTabs.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 41px;
  }

@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 41px;
  }

}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 41px;
  }

}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elCategoriesTabs > .background > .containerMinHeight {
    min-height: 41px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elMission {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMission > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elMission {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMission > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elMission {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMission > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elMission {
    line-height: 36px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMission > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elMissionStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMissionStatement > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elMissionStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMissionStatement > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elMissionStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMissionStatement > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elMissionStatement {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMissionStatement > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}

.AboutUsScreen .elImageHome {
}

.AboutUsScreen .elImageHome.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elImageHome {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elImageHome > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elImageHome > .background > .containerMinHeight {
    min-height: 115px;
  }

@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elImageHome {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elImageHome > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elImageHome > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elImageHome {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elImageHome > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elImageHome > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elImageHome {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elImageHome > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elImageHome > .background > .containerMinHeight {
    min-height: 115px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elMathApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMathApps > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elMathApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMathApps > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elMathApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMathApps > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elMathApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMathApps > * { 
    width: 100%;
  }
  
}

.AboutUsScreen > .layoutFlow > .elList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 50px;
  grid-row-gap: 50px;  /* old name still required by some browsers */
  column-gap: 25px;
  grid-column-gap: 25px;  /* old name still required by some browsers */
}

.AboutUsScreen > .layoutFlow > .elList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .AboutUsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .AboutUsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .AboutUsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .AboutUsScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .AboutUsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .AboutUsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .AboutUsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .AboutUsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentApps > * { 
    width: 100%;
  }
  
}

.AboutUsScreen > .layoutFlow > .elEntertainmentList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 50px;
  grid-row-gap: 50px;  /* old name still required by some browsers */
  column-gap: 25px;
  grid-column-gap: 25px;  /* old name still required by some browsers */
}

.AboutUsScreen > .layoutFlow > .elEntertainmentList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .AboutUsScreen > .layoutFlow > .elEntertainmentList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .AboutUsScreen > .layoutFlow > .elEntertainmentList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .AboutUsScreen > .layoutFlow > .elEntertainmentList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .AboutUsScreen > .layoutFlow > .elEntertainmentList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elEntertainmentList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elEntertainmentList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elEntertainmentList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elEntertainmentList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .AboutUsScreen > .layoutFlow > .elEntertainmentList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elMusicApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicApps > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elMusicApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicApps > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elMusicApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicApps > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elMusicApps {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicApps > * { 
    width: 100%;
  }
  
}

.AboutUsScreen > .layoutFlow > .elMusicList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 50px;
  grid-row-gap: 50px;  /* old name still required by some browsers */
  column-gap: 25px;
  grid-column-gap: 25px;  /* old name still required by some browsers */
}

.AboutUsScreen > .layoutFlow > .elMusicList > div > * {
  display: inline-block;
  min-width: 211px;
  position: relative;
}

  @media (min-width: 1024px) {
    .AboutUsScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .AboutUsScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .AboutUsScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .AboutUsScreen > .layoutFlow > .elMusicList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;
      grid-row-gap: 50px;  /* old name still required by some browsers */
      column-gap: 25px;
      grid-column-gap: 25px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 70px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elMusicList {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100.0% - 69px);
  }
  .AboutUsScreen > .layoutFlow > .elMusicList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 40px;
    width: 300px;
    margin-left: 15px;
  }
  .AboutUsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 40px;
    width: 300px;
    margin-left: 70px;
  }
  .AboutUsScreen > .layoutFlow > .elEllipse > * { 
  width: 300px;
  height: 110px;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 40px;
    width: 298px;
    margin-left: 70px;
  }
  .AboutUsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 40px;
    width: 298px;
    margin-left: 70px;
  }
  .AboutUsScreen > .layoutFlow > .elEllipse > * { 
  width: 298px;
  height: 109px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 35px;
  }
  .AboutUsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -75px;
    width: 238px;
    margin-left: 100px;
  }
  .AboutUsScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .AboutUsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elText2 {
    line-height: 30px;
    position: relative;
    margin-top: -74px;
    width: 236px;
    margin-left: 100px;
  }
  .AboutUsScreen > .layoutFlow > .elText2 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elComingSoon {
    line-height: 36px;
    position: relative;
    margin-top: 70px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoon > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elComingSoon {
    line-height: 36px;
    position: relative;
    margin-top: 70px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoon > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elComingSoon {
    line-height: 36px;
    position: relative;
    margin-top: 70px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoon > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elComingSoon {
    line-height: 36px;
    position: relative;
    margin-top: 70px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoon > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elComingSoonText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoonText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elComingSoonText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoonText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elComingSoonText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoonText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elComingSoonText {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elComingSoonText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy {
    line-height: 36px;
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyLink > * { 
    width: 100%;
  }
  
}

.AboutUsScreen .elPrivacyPolicyButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.AboutUsScreen .elPrivacyPolicyButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    width: 200px;
    margin-left: 10px;
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton > * { 
  width: 200px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    width: 200px;
    margin-left: 10px;
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton > * { 
  width: 200px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: 10px;
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton > * { 
  width: 199px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton {
    line-height: 50px;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: 10px;
  }
  .AboutUsScreen > .layoutFlow > .elPrivacyPolicyButton > * { 
  width: 199px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elContactUs {
    line-height: 36px;
    position: relative;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactUs > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elContactDetails {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100.0% - 15px);
  }
  .AboutUsScreen > .layoutFlow > .elContactDetails > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .AboutUsScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .AboutUsScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .AboutUsScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elEmailLink {
    line-height: 30px;
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .AboutUsScreen > .layoutFlow > .elEmailLink > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 220px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 218px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  
}

.AboutUsScreen .elFooter {
}

.AboutUsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 211px;
  }

@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 211px;
  }

}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 210px;
  }

}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AboutUsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .AboutUsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 210px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 164px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 163px;
  }
  .AboutUsScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 10px;
  }
  
}

.AboutUsScreen .elHeaderComponent {
}

.AboutUsScreen .elHeaderComponent.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 70px;
  }
  .AboutUsScreen > .screenFgContainer > .foreground > .elHeaderComponent > .background > .containerMinHeight {
    min-height: 70px;
  }

}

/* This component has a fixed-size layout */
  .MusicItem {
    min-width: 211px; /* This component has a fixed size */
  }


  .MusicItem > .background > .elClickableBg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 139px;
  }


  .MusicItem > .layoutFlow > .elPicture {
    position: relative;
    margin-top: 10px;
    width: 70px;
    margin-left: 0px;
  }
  .MusicItem > .layoutFlow > .elPicture > * { 
  width: 70px;
  }
  


  .MusicItem > .layoutFlow > .elTitle {
    line-height: 22px;
    position: relative;
    margin-top: -70px;
    margin-right: 5px;
    width: calc(100.0% - 5px);
  }
  .MusicItem > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .MusicItem > .layoutFlow > .elPictureGoogleBadge {
    position: relative;
    margin-top: 5px;
    width: 120px;
    margin-left: 80px;
  }
  .MusicItem > .layoutFlow > .elPictureGoogleBadge > * { 
  width: 120px;
  }
  


  .MusicItem > .layoutFlow > .elPictureAppleBadge {
    position: relative;
    margin-top: -44px;
    width: 100px;
    margin-left: 230px;
  }
  .MusicItem > .layoutFlow > .elPictureAppleBadge > * { 
  width: 100px;
  }
  


  .MusicItem > .layoutFlow > .elGoogleQRcode {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: 85px;
  }
  .MusicItem > .layoutFlow > .elGoogleQRcode > * { 
  width: 100px;
  }
  


  .MusicItem > .layoutFlow > .elAppleQRcode {
    position: relative;
    margin-top: -102px;
    width: 100px;
    margin-left: 230px;
  }
  .MusicItem > .layoutFlow > .elAppleQRcode > * { 
  width: 100px;
  }
  

.MusicItem .elAppleHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .MusicItem > .foreground > .elAppleHotspot {
    position: absolute;
    left: 250px;
    top: 39px;
    width: 98px;
    height: 36px;
  }

.MusicItem .elGoogleHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .MusicItem > .foreground > .elGoogleHotspot {
    position: absolute;
    left: 140px;
    top: 43px;
    width: 103px;
    height: 27px;
  }

